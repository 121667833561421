import {MatDialogConfig} from '@angular/material/dialog';
import {MatSortable} from "@angular/material/sort";
import {
  BreakDownServiceCalendar,
  FiltersInspectionPartTypesTable,
  FiltersInstallationPartGroupsTable,
  FiltersInstallationPartsTable,
  FiltersLocationsTable, FiltersLogsTable,
  FiltersMaterialsTable,
  FiltersNotifiersTable,
  FiltersProjectSectionsTemplatesTable,
  FiltersProjectsTable,
  FiltersReservationItemsTable,
  FiltersTicketsTable,
  FiltersUsersTable,
  FiltersWorkOrderPeriodsTable,
  FiltersWorkOrdersTable,
  TicketStateEnum,
  WorkTypeEnum
} from "@app/core/models";
import {BarcodeFormat} from "@zxing/library";
import {FiltersMaterialPresetsTable} from '@app/core/models/filters-material-presets-table.model';
import moment from 'moment';

const fullScreenDialogConfig = Object.assign(new MatDialogConfig(), {
  panelClass: ['app-dialog', 'full-screen-dialog'],
  maxWidth: null,
  maxHeight: null
});
const fullScreenWiderDesktopDialogConfig = Object.assign(new MatDialogConfig(), {
  panelClass: ['app-dialog', 'full-screen-dialog--wider-desktop'],
  maxWidth: null,
  maxHeight: null
});

const fullScreenUltraWideDesktopDialogConfig = Object.assign(new MatDialogConfig(), {
  panelClass: ['app-dialog', 'full-screen-dialog--ultra-wide-desktop'],
  maxWidth: null,
  maxHeight: null
});

const primaryColor = {hex: '#0d47a1', rgb: '13,71,161'};
const accentColor = {hex: `#ff4081`, rgb: '255,64,129'};

// `src/styles/exports/_vars.scss`
const breakpoints = [
  {name: 'watch', endsAt: 85},
  {name: 'mp', endsAt: 480},
  {name: 'ml', endsAt: 680},
  {name: 'tp', endsAt: 800},
  {name: 'tl', endsAt: 992},
  {name: 'd', endsAt: 1200},
  {name: 'dl', endsAt: 1600},
  {name: 'dxl', endsAt: 1900},
  {name: 'dxxl', endsAt: 9999},
]
const breakPointRanges: { min: number, max: number, name: string }[] = [];
breakpoints.forEach((breakpoint, i) => {
  let min = 0;
  if (i > 0) {
    min = breakpoints[i - 1].endsAt + 1;
  }
  breakPointRanges.push({min: min, max: breakpoint.endsAt, name: breakpoint.name})
});

export const oldUserRoles = [
  'superAdmin',
  'admin',
  'coordinator',
  'mechanic',
  'sensorObserver',
  'external',
  'externalCoordinator',
  'externalEventPlanner',
  'projectLeader'
] as const;
export type OldUserRoles = typeof oldUserRoles[number];

export const userRoles = [
  {type: oldUserRoles[0], enum: 0, label: 'SuperAdmin'},
  {type: oldUserRoles[1], enum: 1, label: 'Admin'},
  {type: oldUserRoles[2], enum: 2, label: 'Coördinator'},
  {type: oldUserRoles[3], enum: 3, label: 'Monteur'},
  {type: oldUserRoles[4], enum: 4, label: 'Sensor observator'},
  {type: oldUserRoles[5], enum: 5, label: 'Externe'},
  {type: oldUserRoles[6], enum: 6, label: 'Externe Coördinator'},
  {type: oldUserRoles[7], enum: 7, label: 'Externe Planner'},
  {type: oldUserRoles[8], enum: 8, label: 'Projectleider'},
] as const;
export type UserRole = typeof userRoles[number];

export const CanViewAndSendPDFuserRoles: OldUserRoles[number][] = ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'external', 'externalCoordinator', 'externalEventPlanner'];

export const hourTypes = [
  {type: 'mechanicOutdoor', enum: 0, label: 'Monteur buiten'},
  {type: 'mechanicIndoor', enum: 5, label: 'Monteur binnen'},
  {type: 'performer', enum: 1, label: 'Uitvoerder / werkvoorbereider'},
  {type: 'projectLeader', enum: 2, label: 'Projectleider'},
  {type: 'engineer', enum: 3, label: 'Engineer'},
  {type: 'softwareEngineer', enum: 4, label: 'Softw. Engineer'},
] as const;
export type HourType = typeof hourTypes[number];

export const breakDownServiceColor = '#d3afd9'; // Mirrors $baseColors.break-down-service @line 98:_helper-classes.scss

export const breakDownServiceCalendars = [
  {
    type: 'coordinator',
    label: 'Coordinator'
  },
  {
    type: 'mechanic_1',
    label: 'Monteur #1'
  },
  {
    type: 'mechanic_2',
    label: 'Monteur #2'
  },
  {
    type: 'mechanic_3',
    label: 'Monteur #3'
  },
];

export const appConfig = {
  ba9123jj12klasd: 'jkl123901249',
  bingApiKey: 'QuTq1DSbCD8U8LbxCUof~gSpFFnnGnh28I5X_qDiIoA~At5hBJ3IFm1lPhY-JLn-tqJBbuOWsuzINtlh1ZF5x4Qv2Gq3Dr-CtCgSw_D2MJae',
  outSmartBaseHref: 'https://office.out-smart.com/backoffice_werkbon.php?RefererTitle=BACKOFFICE_WERKBONNEN_LIJSTWEERGAVE&sheet=',
  firstDate: '2021-01-01',

  breakDownServiceCalendars: breakDownServiceCalendars as BreakDownServiceCalendar[],

  // Main Office Location
  officeLocation: {
    street: 'Nieuwland Parc 14b',
    city: 'Alblasserdam',
  },

  // Types Defined in FED
  hourTypes,
  userRoles,
  oldUserRoles,

  // Scan
  scanFormats: [BarcodeFormat.QR_CODE] as BarcodeFormat[],

  // Charts
  chartColors: {
    defaultColors: {
      normal: `rgba(${primaryColor.rgb}, .5)`,
      hover: `rgba(${primaryColor.rgb}, .9)`,
    },
    activeColors: {
      normal: `rgba(${accentColor.rgb}, .9)`,
      hover: `rgba(${accentColor.rgb}, .9)`,
    },
  },

  // Sensors
  sensorInactiveMinutesBeforeWarn: 50,

  // TicketStates
  ticketStateHoldEnums: [TicketStateEnum.HoldEpc, TicketStateEnum.HoldCustomer],

  // WorkTypes
  workTypeEnumsAllowingMultipleLocations: [WorkTypeEnum.Assembly, WorkTypeEnum.Inspection, WorkTypeEnum.Maintenance],

  // MechanicsCalendars
  unassignedWorkOrdersMechanicId: 'unassigned',
  calendarIdIteration: 'calendar-detail-',

  // Hour Reports
  hourReportMinDate: new Date('2023-06-01'),

  // mainSearchQueryParams
  mainSearchQueryParams: {
    query: 'mainSearchQuery',
    startDate: 'mainSearchStartDate',
    endDate: 'mainSearchEndDate',
  },

  // granularityFormOptions
  granularityFormOptions: [
    {label: 'Maand', value: 'month'},
    {label: 'Week', value: 'week'},
    {label: 'Dag', value: 'day'},
    {label: 'Per 4 uur', value: 'average4hours'},
    {label: 'Uur', value: 'hour'},
    // {label: 'Toon alle', value: 'quarterOfAnHour'},
  ],

  // LocalStorage
  localStorageKeys: {
    prevUrl: 'prevUrl',
    lastSuccessFulNavigationUrl: 'lastSuccessFulNavigationUrl',
    mailAsFileEmailAddress: 'mailAsFileEmailAddress',
    urlToOpenAfterApiBackOnline: 'urlToOpenAfterApiBackOnline',
    email: 'email',
    user: '454987123safCASD',
    oldUser: 'user',

    // Cockpit
    selectedCockpitType: 'selectedCockpitType',
    mechanicCockpitWorkOrders: 'mechanicCockpitWorkOrders',
    cockpitTicketsWorkTypeIds: 'cockpitTicketsWorkTypeIds',
    cockpitTicketsMaxObjectsCount: 'cockpitTicketsMaxObjectsCount',
    locationDetailSensorGroups: (locationId: string) => {
      return `locationDetailExpandedSensorGroups_${locationId}`
    },

    // Photos
    selectFirstPhotoByDefault: 'selectFirstPhotoByDefault',

    // Locations
    locationCalendarSelectedView: 'locationCalendarSelectedView',
    locationsListView: 'locationsListView',

    // Shared Calendar
    calendarSelectedDetailMode: 'calendarSelectedDetailMode',
    calendarSelectedDaysOfWeek: 'calendarSelectedDaysOfWeek',
    calendarSelectedView: 'calendarSelectedView',
    calendarHideNavDetails: 'calendarHideNavDetails',
    calendarEventsHideDetailsInWeekCompactMode: 'calendarEventsHideDetailsInWeekCompactMode',

    // Calendar - Mechanics
    mechanicsCalendarsSelectedMechanicIds: 'mechanicsCalendarsSelectedMechanicIds',
    mechanicsCalendarsShowMechanicsPanel: 'mechanicsCalendarsShowMechanicsPanel',
    mechanicsCalendarsShowUnassignedPanel: 'mechanicsCalendarsShowUnassignedPanel',
    mechanicsCalendarsAlwaysOpenToday: 'mechanicsCalendarsAlwaysOpenToday',
    mechanicsCalendarsViewDate: 'mechanicsCalendarsViewDate',
    mechanicsCalendarsExplainerConfirmed: 'mechanicsCalendarsExplainerConfirmed',

    // Calendar - Breakdown Service
    breakDownServiceCalendarsAlwaysOpenToday: 'breakDownServiceCalendarsAlwaysOpenToday',
    breakDownServiceCalendarsViewDate: 'breakDownServiceCalendarsViewDate',
    breakDownServiceCalendarsExplainerConfirmed: 'breakDownServiceCalendarsExplainerConfirmed',

    // Calendar - Reservations
    reservationsCalendarsSelectedItemsIds: 'reservationsCalendarsSelectedItemsIds',
    reservationsCalendarsShowItemsPanel: 'reservationsCalendarsShowItemsPanel',
    reservationsCalendarsViewDate: 'reservationsCalendarsViewDate',
    reservationsCalendarsAlwaysOpenToday: 'reservationsCalendarsAlwaysOpenToday',
    reservationsCalendarsExplainerConfirmed: 'reservationsCalendarsExplainerConfirmed',

    // Occupancy Rate
    occupancyRateState: 'occupancyRateState',
    occupancyRateExplainerConfirmed: 'occupancyRateExplainerConfirmed',
    occupancyRateDisplayedHourTypes: 'occupancyRateDisplayedHourTypes',
    occupancyRateAlwaysOpenToday: 'occupancyRateAlwaysOpenToday',
    occupancyRateViewDate: 'occupancyRateViewDate',

    // Hour Reports
    hourReportsAlwaysOpenToday: 'hourReportsAlwaysOpenToday',
    hourReportsRateState: 'hourReportsRateState',

    // Work Orders
    tryOpenPrevPageOnWorkOrderClose: 'tryOpenPrevPageOnWorkOrderClose',

    // Tables
    tables: {
      tickets: {
        sort: 'ticketsTableSort',
        filter: (tableId: string) => {
          return `ticketsTableFilter_${tableId}`
        },
        columns: 'ticketsTableColumns',
        pagination: (tableId: string) => {
          return `ticketsTablePagination_${tableId}`
        },
      },
      workOrders: {
        sort: 'workOrdersTableSort',
        filter: (tableId: string) => {
          return `workOrdersTableFilter_${tableId}`
        },
        columns: 'workOrdersTableColumns',
        pagination: (tableId: string) => {
          return `workOrdersTablePagination_${tableId}`
        },
      },
      locations: {
        sort: 'locationsTableSort',
        filter: 'locationsTableFilter',
        pagination: (tableId: string) => {
          return `locationsTablePagination_${tableId}`
        },
      },
      customers: {
        sort: 'customersTableSort',
        filter: 'customersTableFilter',
        pagination: (tableId: string) => {
          return `customersTablePagination_${tableId}`
        },
      },
      sensors: {
        sort: 'sensorsTableSort',
        filter: 'sensorsTableFilter',
        pagination: (tableId: string) => {
          return `sensorsTablePagination_${tableId}`
        },
      },
      projects: {
        sort: 'projectsTableSort',
        filter: 'projectsTableFilter',
        pagination: (tableId: string) => {
          return `projectsTablePagination_${tableId}`
        },
      },
      projectSectionsTemplates: {
        sort: 'projectSectionsTemplatesTableSort',
        filter: 'projectSectionsTemplatesTableFilter',
        pagination: (tableId: string) => {
          return `projectSectionsTemplatesTablePagination_${tableId}`
        },
      },
      users: {
        sort: 'usersTableSort',
        filter: 'usersTableFilter',
        pagination: (tableId: string) => {
          return `usersTablePagination_${tableId}`
        },
      },
      emails: {
        sort: 'emailsTableSort',
        filter: 'emailsTableFilter',
        pagination: (tableId: string) => {
          return `emailsTablePagination_${tableId}`
        },
      },
      externalFiles: {
        sort: 'externalFilesTableSort',
        filter: 'externalFilesTableFilter',
        pagination: (tableId: string) => {
          return `externalFilesTablePagination_${tableId}`
        },
      },
      materials: {
        sort: 'materialsTableSort',
        filter: 'materialsTableFilter',
        pagination: (tableId: string) => {
          return `materialsTablePagination_${tableId}`
        },
      },
      workOrderPeriods: {
        sort: 'workOrderPeriodsTableSort',
        filter: 'workOrderPeriodsFilter',
        pagination: (tableId: string) => {
          return `workOrderPeriodsTablePagination_${tableId}`
        },
      },
      rateTemplates: {
        sort: 'rateTemplatesTableSort',
        filter: 'rateTemplatesFilter',
        pagination: (tableId: string) => {
          return `rateTemplatesTablePagination_${tableId}`
        },
      },
      surchargeGroupTemplates: {
        sort: 'surchargeGroupTemplatesTableSort',
        filter: 'surchargeGroupTemplatesFilter',
        pagination: (tableId: string) => {
          return `surchargeGroupTemplatesTablePagination_${tableId}`
        },
      },
      projectOverview: {
        sort: 'projectOverviewTableSort',
        filter: 'projectOverviewTableFilter',
        pagination: (tableId: string) => {
          return `projectOverviewTablePagination_${tableId}`
        },
      },
      safetyIncidents: {
        sort: 'safetyIncidentsTableSort',
        filter: 'safetyIncidentsTableFilter',
        pagination: (tableId: string) => {
          return `safetyIncidentsTablePagination_${tableId}`
        },
      },
      safetyDocuments: {
        sort: 'safetyDocumentsTableSort',
        filter: 'safetyDocumentsTableFilter',
        pagination: (tableId: string) => {
          return `safetyDocumentsTablePagination_${tableId}`
        },
      },
      safetyToolboxes: {
        sort: 'safetyToolboxesTableSort',
        filter: 'safetyToolboxesTableFilter',
        pagination: (tableId: string) => {
          return `safetyToolboxesTablePagination_${tableId}`
        },
      },
      materialPresets: {
        sort: 'materialPresetsTableSort',
        filter: 'materialPresetsTableFilter',
        pagination: (tableId: string) => {
          return `materialPresetsTablePagination_${tableId}`
        },
      },
      installationParts: {
        sort: 'installationPartsTableSort',
        filter: 'installationPartsTableFilter',
        pagination: (tableId: string) => {
          return `installationPartsTablePagination_${tableId}`
        },
      },
      installationPartGroups: {
        sort: 'installationPartGroupsTableSort',
        filter: 'installationPartGroupsTableFilter',
        pagination: (tableId: string) => {
          return `installationPartGroupsTablePagination_${tableId}`
        },
      },
      notifiers: {
        sort: 'notifiersTableSort',
        filter: 'notifiersTableFilter',
        pagination: (tableId: string) => {
          return `notifiersTablePagination_${tableId}`
        },
      },
      reservationItems: {
        sort: 'reservationItemsTableSort',
        filter: 'reservationItemsTableFilter',
        pagination: (tableId: string) => {
          return `reservationItemsTablePagination_${tableId}`
        },
      },
      inspectionPartTypes: {
        sort: 'inspectionPartTypesTableSort',
        filter: 'inspectionPartTypesTableFilter',
        pagination: (tableId: string) => {
          return `inspectionPartTypesTablePagination_${tableId}`
        },
      },
      logs: {
        sort: 'logsTableSort',
        filter: 'logsTableFilter',
        pagination: (tableId: string) => {
          return `logsTablePagination_${tableId}`
        },
      },
    },
  },

  // Default Options
  yesNoOptions: [
    {value: true, label: 'Ja'},
    {value: false, label: 'Nee'},
  ],
  priorities: [
    1, 2, 3
  ],
  // Less page size options for mobile
  pageSizeOptions: window.innerWidth > 750 ? [25, 50, 100, 200] : [25, 50],
  defaultTableColumns: {

    tickets: [ // Reminder: also update `.filters__checkboxes` list  in `tickets-table-columns-form.component.html`
      'ticketNumber',
      'ticketState',
      'companyTypeId',
      'locations',
      'priority',
      'comment',
      'notificationDate',
      'installationPart',
      'durationArrival',
      'durationFunctionalRepair',
      'durationPermanentRepair',
      'durationBlockageRoad',
      'durationBlockageWater',
      'actions'
    ],
    workOrders: [ // Reminder: also update `.filters__checkboxes` list  in `work-orders-table-columns-form.component.html`
      'referenceId',
      'workOrderStatusId',
      'startWorkDate',
      'location',
      'workTypeId',
      'mechanicName',
      'mechanicFunction',
      'allCommentsCombined',
      'errorNotAllPeriodsInExact',
      'customerName',
      'projectName',
      'workPeriodsCount',
      'totalKilometers',
      'photosCount',
      'materialsCount',
      'reservationsCount',
      'actions'
    ],
  },
  defaultTableSorting: {
    tickets: {id: 'ticketNumber', start: 'desc'} as MatSortable,
    workOrders: {id: 'referenceId', start: 'desc'} as MatSortable,
    locations: {id: 'name', start: 'asc'} as MatSortable,
    customers: {id: 'name', start: 'asc'} as MatSortable,
    sensors: {id: 'name', start: 'asc'} as MatSortable,
    projects: {id: 'name', start: 'asc'} as MatSortable,
    users: {id: 'name', start: 'asc'} as MatSortable,
    emails: {id: 'from', start: 'asc'} as MatSortable,
    externalFiles: {id: 'from', start: 'asc'} as MatSortable,
    materials: {id: 'articleNumber', start: 'asc'} as MatSortable,
    workOrderPeriods: {id: 'beginDate', start: 'asc'} as MatSortable,
    rateTemplates: {id: 'name', start: 'asc'} as MatSortable,
    surchargeGroupTemplates: {id: 'name', start: 'asc'} as MatSortable,
    safetyIncidents: {id: 'name', start: 'asc'} as MatSortable,
    safetyDocuments: {id: 'title', start: 'asc'} as MatSortable,
    safetyToolboxes: {id: 'title', start: 'asc'} as MatSortable,
    materialPresets: {id: 'title', start: 'asc'} as MatSortable,
    installationParts: {id: 'name', start: 'asc'} as MatSortable,
    installationPartGroups: {id: 'name', start: 'asc'} as MatSortable,
    notifiers: {id: 'name', start: 'asc'} as MatSortable,
    reservationItems: {id: 'name', start: 'asc'} as MatSortable,
    inspectionPartTypes: {id: 'name', start: 'asc'} as MatSortable,
    logs: {id: 'date', start: 'desc'} as MatSortable,
  },
  defaultTableFilters: {
    tickets: {
      query: null,
      workTypeIds: [],
      ticketStateEnums: [
        TicketStateEnum.Active,
        TicketStateEnum.HoldEpc,
        TicketStateEnum.HoldPlanned,
        TicketStateEnum.HoldCustomer,
      ],
      prio: null,
      projectQuery: null,
      useDurationArrival: null,
      durationArrivalMin: null,
      durationArrivalMax: null,
      useDurationFunctionalRepair: null,
      durationFunctionalRepairMin: null,
      durationFunctionalRepairMax: null,
      useDurationPermanentRepair: null,
      durationPermanentRepairMin: null,
      durationPermanentRepairMax: null,
      useDurationBlockageRoad: null,
      durationBlockageRoadMin: null,
      durationBlockageRoadMax: null,
      useDurationBlockageWater: null,
      durationBlockageWaterMin: null,
      durationBlockageWaterMax: null,
      erroneousTickets: null,
      readyToCompleteTickets: null,
    } as FiltersTicketsTable,
    workOrders: {
      query: null,
      workOrderStatusIds: null,
      workTypeIds: null,
      mechanicIds: null,
      isArchived: false,
      projectQuery: null
    } as FiltersWorkOrdersTable,
    locations: {query: null} as FiltersLocationsTable,
    customers: {query: null} as FiltersLocationsTable,
    sensors: {query: null} as FiltersUsersTable,
    users: {
      query: null,
      isActive: true
    } as FiltersUsersTable,
    emails: {query: null} as FiltersUsersTable,
    projects: {
      query: null,
      isActive: true
    } as FiltersProjectsTable,
    projectSectionsTemplates: {query: null} as FiltersProjectSectionsTemplatesTable,
    materials: {query: null} as FiltersMaterialsTable,
    workOrderPeriods: {query: null} as FiltersWorkOrderPeriodsTable,
    materialPresets: {query: null} as FiltersMaterialPresetsTable,
    installationParts: {query: null, installationPartGroupId: null} as FiltersInstallationPartsTable,
    installationPartGroups: {query: null} as FiltersInstallationPartGroupsTable,
    notifiers: {query: null} as FiltersNotifiersTable,
    reservationItems: {query: null} as FiltersReservationItemsTable,
    inspectionPartTypes: {query: null} as FiltersInspectionPartTypesTable,
    logs: {
      query: null,
      levels: null,
      logger: null,
      from: moment().subtract(24, 'hours').toDate(),
      till: new Date()
    } as FiltersLogsTable,
  },

  // Components
  fullScreenDialogConfig: fullScreenDialogConfig as MatDialogConfig,
  fullScreenWiderDesktopDialogConfig: fullScreenWiderDesktopDialogConfig as MatDialogConfig,
  fullScreenUltraWideDesktopDialogConfig: fullScreenUltraWideDesktopDialogConfig as MatDialogConfig,

  // Duration Picker
  durationPickerOptions: {
    zeroValue: null,
    showPreview: false,
    showYears: false,
    showMonths: false,
    showWeeks: false,
    showDays: false,
    showSeconds: false,
    showLetters: false,
    customOutputFormat: 'seconds'
  },

  // misc
  hourMinutesFormat: 'HH:mm',
  hourMinutesFormatShort: 'H:mm',
  dateMonthOnlyFormat: 'dd-MM',
  dayDateMonthFormat: 'cccccc dd-MM',
  dateMonthAbbrevOnlyFormat: 'EEEE d MMM',
  dateFormat: 'dd-MM-yy',
  dateWithDayOfWeekFormat: 'cccccc dd-MM-yy',
  dateHourMinutesFormat: 'dd-MM-yy HH:mm',
  dateWithDayOfWeekHourMinutesFormat: 'cccccc dd-MM-yy HH:mm',
  dateTimeFormat: 'dd-MM-yy HH:mm:ss',
  maxSearchResults: 200,
  breakPointRanges,

  // keys
  panelKey: 'a',
  panelKeyAlt: 'w',
  specialKeys: [
    'Backspace',
    'Tab',
    'Enter',
    'Shift',
    'Control',
    'Alt',
    'CapsLock',
    'Escape',
    'PageUp',
    'PageDown',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'PrintScreen',
    'Insert',
    'Delete',
  ],
}
